import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logIn } from '../../redux/slices/authSlice';
import { IUserInfo } from '../../redux/constants';
import { index } from '../../api/authorization';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../redux/storeToolkit';
import { useAlert } from 'react-alert';
import jwt_decode from 'jwt-decode';
import { TextField } from '../UI-Lib/TextField_v2';
import { ReactSVG } from 'react-svg';
import { systemPreferencesAPI } from '../../api/systemPreferences/systemPreferencesAPI';
import * as queryString from 'query-string';
import { ILoginType } from '../../api/systemPreferences/models/AvailableLoginTypes/ILoginType';
import { Preloader } from '../Preloader';
import {IUserLoginPasswordParamsDTO} from '../../api/authorization/models/IUserLoginPasswordParamsDTO';
import styles from './index.module.scss';
import {Button} from '../UI-Lib';

export const allowedExtURI = [
  'gemba',
  'rd-awards',
  'rnd',
  'sberboost-expert',
  'sberboost'
]

const Auth: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const [controlsDisabled, setControlsDisabled] = useState(false); // делаем кнопки недоступными пока идёт запрос
  const [authTypes, setAuthTypes] = useState<Array<ILoginType>>([]);
  const userRole = useSelector((state: RootState) => state.auth.role);

  useEffect(() => {
    // запросим у сервера настройку, разрешено ли нам входить по логину/паролю
    const getStatusLoginEntranceAsync = async () => {
      try {
        setLoading(true);
        const status = await systemPreferencesAPI.getAvailableTypeEntrance();
        setAuthTypes(status?.value?.authorization || []);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    if (userRole === 'REGISTERED') {
      navigate('/');
      return;
    }
    getStatusLoginEntranceAsync();
    // если есть параметр from=legacy, то пришли за логином с текущей площадки
    const searchParams: string = location.search;
    const searchParamsObj = queryString.parse(searchParams);
    if (searchParamsObj.from === 'legacy') localStorage.setItem('fromLegacy', '1'); // установим признак
    // проверяем список разрешённых переходов
    // если есть параметр target_uri и он равен gemba, то сохраняем его в currentRoute
    const targetUri = searchParamsObj.target_uri as string || '';

    if (
      allowedExtURI.includes(targetUri) ||
      targetUri.startsWith('/sberboost/')
    ) {
        // if (targetUri === 'gemba') localStorage.setItem('currentRoute',`/gemba/`);
        // if (targetUri === 'rd-awards') localStorage.setItem('currentRoute',`/rd-awards/`);
        // if (targetUri === 'rnd');
      // для сбербуста не перезыписываем путь для редиректа
        if (allowedExtURI.includes(targetUri) && !targetUri.startsWith('sberboost'))
          localStorage.setItem('currentRoute',`/${targetUri}/`);

        // убираем данную строку, чтоб н перезаписавался путь
        // if (targetUri.startsWith('/sberboost/')) localStorage.setItem('currentRoute', targetUri);

        // флаг необходимости перезагрузки страницы
        if (searchParamsObj.reload === 'true') localStorage.setItem('reload', '1');
    }
  }, []);

  // имя пользователя и пароль
  // const username = useRef<string>('');
  // const password = useRef<string>('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();

  const surveysAlert = useAlert(); // для показа алертов

  // функция записи инфо о юзере в редакс
  const saveUserInRedux = (accessToken: string) => {
    const jwtDecoded: any = jwt_decode(accessToken);
    const user: IUserInfo = {
      name: jwtDecoded?.user_name || 'User',
      role: jwtDecoded?.user_type || 'user',
      access_token: accessToken,
    };
    dispatch(logIn(user));
  };

  const loginUser = () => {
    async function getTokenFromAPI() {
      try {
        // setControlsDisabled(true)
        const userLoginPassword: IUserLoginPasswordParamsDTO = {
          userName: username,
          userPass: password,
        };
        const userToken = await index.getUserToken(userLoginPassword);

        saveUserInRedux(userToken.accessToken || '');

        // редирект на место, откуда пришли
        const url = localStorage.getItem('currentRoute') || '/';
        /**
         * частный случай
         * если переход на "gemba" или "sberboost" и есть флаг перезагрузки,
         * то перезагружаем страницу
         */
        if ((!!allowedExtURI.map(uri => `/${uri}/`).find(allowedURI => url.startsWith(allowedURI)) || url.startsWith('/sberboost/') || url.startsWith('/sberboost-expert'))) {
          localStorage.removeItem('reload');
          window.location.replace(url);
        } else {
          navigate(url); // редирект
        }
      } catch (error) {
        surveysAlert.show('Ошибка авторизации', { type: 'error' });
        setControlsDisabled(false);
      }
    }
    getTokenFromAPI();
  };

  const loginSudir = () => {
    // редиректим браузер на судировский адрес
    window.location.replace('/api/authorization/auth-sudir');
  };

  const closeAndContinue = () => {
    // const url = localStorage.getItem('urlRedirect') || '/';
    // localStorage.removeItem('urlRedirect');
    const url = localStorage.getItem('currentRoute') || '/';
    navigate(url);
  };

  // если в access_token поле user_role === REGISTERED, значит пользоваетль залогинен
  // значит не надо ему показывать данную форму (редирект на главную)
  // if (userRole === 'REGISTERED')
  //   return <Navigate to='/'/>

  if (loading) return <Preloader />;

  // вырожденный случай -
  // когда доступный способ авторизации только один и это - вход по сертификату,
  // то автоматом редиректим юзера на СУДИР
  if (authTypes.length === 1 && authTypes[0].id === 'certificate') {
    loginSudir();
    return <></>;
  }

  return (
    <div className={styles.auth}>
      <div className={styles.auth_form}>
        <ReactSVG src="/img/logo.svg" wrapper="div" />
        {authTypes
          .sort(
            // сначала отсортируем массив так, чтобы логин/пароль был на первом месте
            (a, b) => {
              if (b.id === 'login_password') return 1;
              else return -1;
            }
          )
          .map(
            // а теперь отобразим доступные способы
            (at) => {
              if (at.id === 'login_password')
                return (
                  <React.Fragment key={ at.id }>
                    <TextField
                      type="text"
                      name="username"
                      value={username}
                      className="textfield-events-primary"
                      placeholder="Имя"
                      handleChange={setUsername}//{(event: ChangeEvent<HTMLInputElement>) => (setUsername(event.target.value))}
                      disabled={controlsDisabled || !at.isActive}
                    />

                    <TextField
                      type="password"
                      name="password"
                      value={password}
                      className="textfield-events-primary"
                      placeholder="Пароль"
                      handleChange={setPassword}//{(event: ChangeEvent<HTMLInputElement>) => (setPassword(event.target.value))}
                      disabled={controlsDisabled || !at.isActive}
                    />

                    <Button type="primary" onClick={loginUser} disabled={controlsDisabled || !at.isActive}>
                      Войти пользователем
                    </Button>
                  </React.Fragment>
                );
              if (at.id === 'certificate')
                return (
                  <Button
                    key={at.id}
                    type="primary"
                    onClick={loginSudir}
                    disabled={!!(controlsDisabled && authTypes.find((at) => at.id === 'certificate' && !at.isActive))}
                  >
                    Вход по сертификату
                  </Button>
                );
              return <></>;
            }
          )}

        <Button type="no-fill" onClick={closeAndContinue} disabled={controlsDisabled}>
          Отмена
        </Button>
      </div>
    </div>
  );
};

export { Auth };
